import imgMacadamia from "../assets/macadamia-100.jpg";
import imgAlmendra from "../assets/almendra-100.jpg";
import imgMaranon from "../assets/maranon-100.jpg";
import imgCocoa from "../assets/cocoa-100.jpg";
import imgMani from "../assets/mani-100.jpg";
import imgPink from "../assets/pink-100.jpg";
import imgMatcha from "../assets/matcha-100.jpg";
import imgGolden from "../assets/golden-100.jpg";
import imgCanela from "../assets/canela-100.jpg";
import imgPistacho from "../assets/pistacho-100.jpg";

const THE_PRODS = [
  {
    id: 20,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Negociación por insolvencia",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 1,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "service",
    },
    tax: [],
    customFields: [],
    productKey: null,
    type: "service",
    itemType: null,
  },
  {
    id: 21,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "ICUI pendiente de cobro por cambio de tarifa del 10 % al 15 %",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 1,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "service",
    },
    tax: [],
    customFields: [],
    productKey: null,
    type: "service",
    itemType: null,
  },
  {
    id: 25,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Pistacho 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 25714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 25714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 25378.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 32000,
      initialQuantityDate: "2024-07-15",
      unit: "unit",
      unitCost: 18024,
      availableQuantity: 30820,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 32000,
          availableQuantity: 30820,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 16,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 15042.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 14874.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 15042.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 8750,
      availableQuantity: 5000,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 5000,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 17,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Macadamia 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 15714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 15210.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 15714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 7446,
      availableQuantity: 8336,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 8336,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 18,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Almendra 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 13782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 12521.5,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 13782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 5365,
      availableQuantity: 8170,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 8170,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 19,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Maní 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 5798.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 5546.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 5798.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 2820,
      availableQuantity: 9755,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 9755,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 22,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón Cocoa 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 16134.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 5000000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 9122,
      availableQuantity: 4999475,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 5000000,
          availableQuantity: 4999475,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 23,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón Canela 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 16134.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 50000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 10006,
      availableQuantity: 49587,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 50000,
          availableQuantity: 49587,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 24,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Macadamia Pinkfud 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 23109.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 23109.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 22773.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 50000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 13875,
      availableQuantity: 49335,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 50000,
          availableQuantity: 49335,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 13,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón Canela 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 36303,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 35100,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000000,
      initialQuantityDate: "2020-09-20",
      unit: "unit",
      unitCost: 25079,
      availableQuantity: 999994546,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000000,
          availableQuantity: 999994546,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 21,
        name: "marañoncanela 2020-09-20 at 2.56.09 PM.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1600632668-mara%C3%B1oncanela+2020-09-20+at+2.56.09+PM.png?Expires=1733410052&Signature=JNyZFKmSUWgw761WUa-UeT9w65cpZJMGiJdkYyKuHO8cEq-UKSpGe9~QHmkr~8a16wPPyKaAPs62ZPRTLZ0869FOr1Alw3624csa~QjbRsKA3Hl0Y8HKUbjaiHl4BDKFGMZ51HVBJKZpwWZDvpR4c84TTbw3y5jb7Q5l2y~fK1DMrY9eTk8pg7jNDYviQkkd6Zj5tAxAveVrg7YQZaIgFevRSbw0ti10LHroF1D4KsafV8abozIEOf8eERvG7mqA9ekfHeNFjUQcaH37G~5r67sX2kYPnWs-vdGHJp-KNOHYdROkgZZ2Ma8b6Rl2iLmnnj2cH7v1nltAwbah~h6H1A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 3,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 33782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 33277.99,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 26555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 33782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 21712,
      availableQuantity: 958240,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 958240,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 1,
        name: "Marañon.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1593882160-Mara%C3%B1on.jpg?Expires=1733410052&Signature=epDvUUZQn5Xe9veexE8IStHjWCsN3dRquU-wF8skevexHVr9mY6vITfJtYsJjyACVRUFfJTkYpLQTy4jWGwhX~Qk3AvGIUskHX65WBloSrw-k7YpZ76mA8~3zjlmqTUZLXXF2yskSXsd4InbAT3oTWv~gM-WdBzYuUNaE0UymCOfsC3wZKAbIGGyl92UUtZwFTlPglSw5N007x7Sl2jAuNk2Y6vXS2FmW-dlQzvXU1YjPMr03G7CRfsjM3ssQQjcdFgxyObLeo1Z13BH2yJRf1Bc~ogbnZ4Y8dDxoJ8w6OmntKEYDB7UjG~ACTbAex7i~ZIqyx5g1~iUcH2P4SRrLQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 19,
        name: "maranon_1270bf70.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575349-maranon_1270bf70.jpg?Expires=1733410052&Signature=K5EJICXrCfnzwE1jycn0mRXt22ks7D8uUHT7wjKcwmOKyBQgXEXui8~hrAidxsdPCJ85dRi2lrrkjQN2JyUOneFGREh~Dys02RdS5IXdyNSPduqJGFmmtikB2B10t1wIuYM30-7UcBGm8BzVcrzUxd-ezDPiW7RB9mW4qArXrTxBMolVLESnlrNk8qkVshqVJ1aWSEDdwiBpLNNfHV6uMI-N9QQMQi25ZXql7Y9KSWlWg9luZOCGybZwF6rp2jHUZTmOm3Pry8qRDt2EmU82pJuz9zLrEA47sRhiJzL3X~373~g-nf5l-CXanv8Dz8vbOJ7OLbIrhkvgFI95hget6g__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 5,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón Cocoa 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 36303,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 30252,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 10000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 22528,
      availableQuantity: 9990570,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000000,
          availableQuantity: 9990570,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 12,
        name: "Imagen4.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594574048-Imagen4.png?Expires=1733410052&Signature=lJbwmRn8SVwCIyvzrmnaIlqjSXVuHDwGkpEjoXtb4I53TQW2JAwwaSS0kIrkcQsfdcDEvrqpptsgk80DQoEZGap~Si3bYKLG5kBkd8hy-oUvxZ6BEcrEDFl9hdynYsq4V1vfqt~QX2k6KlmJOvJeI5SUP7vDw7GxxdC9SsHDKRDU7Py2GdYJ4i6VQzohh~AuuKADp0nyX54Nvj0GjMlo3wkTU7evBPtW4wl0sTMJhgKldcl1pZYGcTYuiQt0stFy655BRpsxW7oXrW-4LdBNWQ65cs4Vv5src9NME9a~OcuS8Xiifwdk59SVRpeQ9XQNiMe1sjteeuU1vUNUvj5tdg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 20,
        name: "maranoncocoa_10ee8637.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575358-maranoncocoa_10ee8637.jpg?Expires=1733410052&Signature=nljisZX6MamLv~ByL~h8jYqrnyXQi~yaCjYmiGQiGq2LUGw2XBeq7tyOGSwsLV02QR0SR6yynXWOhNSxpAO4z4ndTtE4SJkMzVL5ns3voCZBfI-28uW2yOfmUa2IXOUe7VdDF5jBoYN86s3vB73wtTWRuCRqtpBT4qAcNAGNfW7U5Cnx7vGLPsohv7GOCwBXJPW7dKMlpriH0IDt9dQwtHwBOTWNLEQb64~eMv9quiOA7yycstqwS~HDL2NrLElCYP0gvMxeUF8hkjRRni7hSRLbKYZEkAOwQlqN6bFqFV8IHTaipFtXYyh8Ta8hY4GVbLwjW~YLRT5FcvJjjhsmBg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 1,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Almendras 230g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 30840.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 28067.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 28571,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 30840.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 13588,
      availableQuantity: 961619,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 961619,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 2,
        name: "almendra.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076804-almendra.jpg?Expires=1733410052&Signature=fjUBihOV2Rebi8e7EFYWIWZW6od~ZvIFsU8FVX7KZtQ~tICPUa0XtXlT1rsUI8x-0Tdg2Ce-fIO14kNfg7UApZYr39dEITxydmFckZsJNV0f7qya8TVWs7HwE09fN7sCaZ-QOWp2FRx796T~wXsgfdE81-Z9z-lReUSCfZRyKA-Mg6Th-eiKp0T5jQTCGVswsY2RCYUN6W1KW3uQoNnEUhGnr-we923YnaWG0y8fr661uMQKgEm08gVAnHRPWJdU6~xA7ApSKmqS3BCpZdVcUuyts1ezpI0j-0RJXTJFidHtnwwsfknfasRBLcl9~rmGtdwOmuSFPc87NeRuwI6H0A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 13,
        name: "almendra_63f7dee4.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575284-almendra_63f7dee4.jpg?Expires=1733410052&Signature=hdc3MFDkxwIUqS8Q3eVpYAa5JYzCszj-S0ZVk1qN2N1vn1mvVK94vjwksnlfQP6r4Y7erBV84RugWw3~qQ8ea8-YdBn4a07W2D4g4itR6C5a0l0nWElwPa6AYLQmSgUc3YOlEOAOGimkUZN0E-GIND0CpvKnRju3MMzpPcItB15sdGtzhCflqfN9K9BYdsuM6QfHDy31g9Vp4rtAusbmuLqRj77NXy1Tq0vVmwJi0QLlqFP4NekoaKyInIQ35urJb7UNoJgrTWWzp5hDRbjMg7G7rBpeHKISPfpzoBo6DgXkZVXpnM-ECbRRoEdZ9kUggFRKAN0bmTmhSPfzGesOOA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 2,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia 230g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 35126.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 34622.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 32857,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 35126.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 18583,
      availableQuantity: 965423,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 965423,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 3,
        name: "Macadamia.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076829-Macadamia.jpg?Expires=1733410052&Signature=BAKLJYiE70kZI8mZw5vq~ueaM69uxJFtXIw291LkjmlLKcJcKZeDKV2fWp8CPQmhIUK7J5ne2yg3hQhMHwV4ZBQ6Ox~wGUzGDy8KyP-wvHsCwxpIJ9izDzLOaBiIvQg-unkrA5gZzda9hGbewYIOqCoasSmkUd8nE2wa4tWvvgSkY-n6EirCGEoUU2nB4OzXF4M7nhxUt8T3~4xoBTGL0I1Yurjok6GrFjjWQtA-KFPvuUzTONVYKMJ85qCojW-Hj7gjYDUqYjsegDHwqwlJgl1TZ5cD6IfvnsmfjxIxL7Ucyv87ptSVx7fHXLR7tCWbWOMv2yUgZEUzQy9IT1SYNQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 14,
        name: "macadamia_2eff4f33.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575296-macadamia_2eff4f33.jpg?Expires=1733410052&Signature=plwUJdbWECeUrNat~X52XceTYzt46Igk-4ZzLI~c80AI4DfScIRIzWOe~Qnil2t9B9Hj~XBCDSU2Lk51qEf0sQwhAFtxWAjQUuZdwCYXiR~cJLFe2T8y9Vys2Qg9fXUnfoPE1HkeEF5F4jvfI4uPyGQTCiMpQsb3b2qnSxBqyO0WFY3JRDVT6DevM-EC-~bVZ-RsdBFlV9ZVozwDn7~j0xHKqfpryd73cGnGytjTWwzOA6f06Hl8F-4KWFZOZE7vI6v1Yu7xipn2QoCgBM2~h5tzFtGH7rJHhNn-VW1wxNTtDuwXWe9ldNZDI1NDJDCHO6lniAp3RnEJ6VAFJ3YSxg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 4,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 12941.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 12269.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 10924,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 12941.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 7480,
      availableQuantity: 964236,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 964236,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 7,
        name: "Mani.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076973-Mani.jpg?Expires=1733410052&Signature=bVoQH4ziLm2RGfCVRNA4XKW-LouSd~YvrrDZgcYrK~wtoM9iZGubJZg4LvqXZDJHns0yBZAsuW~JltbaQSeiT9gYJM1TuHQo8-zScx7HTIBj6zRr5ubqFtrGFML3zIaB0oVg-UIghIbpW004PA2Sa7f6ixlKmVz5-vLXBD6RAJovEeuUVDQQ-SWrNKgFFQv2yyqqP7mld2d5JP8AZnzsFckZKsLP7pyveaZJ7Fi71SMVjQr6iDW9NJCIRWTg5uIWuKOkntFth~kAaac-Ft5JUB6brBgcgRkMp9EBYdpfqbaqhuyVuGH0bR3bE~BH2ji0JvTbUQOMDG-jTRPJpWO04w__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 18,
        name: "mani_78354541.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575340-mani_78354541.jpg?Expires=1733410052&Signature=p576a~N22MvDaYa9Yl8VEWZxlXpG7le71sK6yzkji93ll166SpROzQPaetBw~vF37PnSa7BDJsGWwFxj4kVUoqoZN17kQ2h58qeFhHMCFGsasrRIaXE4ZCAw8BjgUEGA5Ha7H3cuQ4FLqVuMhVh7j5eEOkpzBCOM3gVVVFuPMC3-DCq3gbWKRz-Z8S6o9ejA~HpKJDWi3CGcIA86XKqNr-EZaxEtF3WdkeKD8UV2uk5lKvfpOwI~tJ14XubcSDboNbLIqh3Q0cYKi6K-n0TIAIVpvZ53ayYBAQbjTinqdE6mcheT8irdL88yjciYZckIboyamnZ9Hnon5VGN97tRwg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 6,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Pinkfud 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 20168,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 10000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 14968,
      availableQuantity: 9988219,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000000,
          availableQuantity: 9988219,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 6,
        name: "Pink.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076956-Pink.jpg?Expires=1733410052&Signature=rEjVW8hINM1Sd8-qPcs7vpYAZBJKaV0WZaABA4xi6GOqMw52B4wkdbJ9NDHtxWF388zm~0mHwJ5M~EkYnjMRTIDQA-u~F89SuJ7NZxGN5Om1P0evUoGL8TUwaht5ewqknT3zpJM7aWpMMuEfpkkPv3EM2wMNGzLoaNqtnowtYLEwtgx7GYpGYw9z71VOoUSVslUXJNTZiDparvT3Q7PukRwt4FbvWHOUyJYxVvLQMFptrXQJmNeg5Al-AAec1INXNRXxalISV4VltfrhJVzbEVHN2yXmTZGZ9hMZ6ZUp~wvWV6Iyz4A0vaiF1YMepL0~-4XH8OUJF1wvrZxudoumKg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 17,
        name: "macadamia-pinkfud_45ab54cc.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575328-macadamia-pinkfud_45ab54cc.jpg?Expires=1733410052&Signature=fkhfud4Hs7GLZ86xxJZ90PHC0ZqEavmOKzcMgHm0Im4rLCm2HRWAweeOv3zPGZw7KvGr6yOPzLXesgWaDL9mTbPoYINnTvdRVgL3ae0D2MciOvWSsyRElDCdKSt0~Ukm3~obp~pxlmMPszu03MXlVA0~CPBoCEoM6ebJWNFWyWZVDegReaW-Njp7xlwy5zrUlIDaF3nWkz4abM~--yO184LnQPtUaxhpy0NyqLQp7T1xpOjm8tSP3Zqbmq3SkzBEvxVG-0WUhmN~NatLLGTqAI3g6WD3c-0gLce~IvDiwHGetveQgWlaR6ueHJHhhMghJQwP5LjdIKT2D7HZyGIaLg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 7,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Matchachá 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 20168,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 100000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 11161,
      availableQuantity: 97504,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 100000,
          availableQuantity: 97504,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 5,
        name: "IMG_20200622_145520.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076908-IMG_20200622_145520.jpg?Expires=1733410052&Signature=nhm8zTxH4D1rilicB2pX3s3UGzTN4lLQqomU2eMMIDA~yOafAWqHFkoeqfZzqKAYcXyCNenrR4V5bQUPA4NvQfCErK-t0KD4ukUeTTh3Q3HR2vSq6OLT5tCbRxocGZe-D7VzI1tGFcWDErh0J15MH3YQBbfK1yurp5JFUV4BKRalOUkPKYfD92-R1t2SFkClAleE4F5FSBpqiReqTCeqQ1rcH7c~EAFw9qkFv46ipo8qLB~anqL16b64KK0WB4sXOJM1DeyRUJ1GJpQ5dtMRzvT-F8PLQDJVMDSNC1Qiweflq~JBD3YmwuMgzs4aVO5fTsZ~o3tKMEyGsitHgOwLKg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 8,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077024-Matcha.jpg?Expires=1733410052&Signature=oiNJAatTasuxtELci6dvmBDWNTEIjpskkDvjJrD3X39tK~-62de2~Ju8PoL9NHjayVUN-SrpsqJMyqpqF2~9ZA42v8ocqLN17dkZRc6GQazTu~tEtjADnyMecKZIShCRSP-qct7Jo8YuZcuU5H4sWuwkDZ55BjO5rm2ZxrMs20Wg4958v8ESZ5waXakSxtrFkQWq5LoftUp5LT65aq7jE5SHoMp5BiFkrMSosuMN9Eb9bjjO~1970KrWefvar8Z3HH-02k0nqcFrLVitFK9bBEq3WlBs8ULHk5V5mLNbx7AtndSn-uB0zpi2xVYg3BMIdI-Uu4bfwTFXcbR6sjTmYQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 9,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077114-Matcha.jpg?Expires=1733410052&Signature=R7QZ9Ko85TZ6MkKXAQ5x4B7p9o~QLBoEuTUP1ZQhir8h4KtVI5ObxR2i-5Do9T22bFyW54AO9BVvXqjEKe0CYSPPLszBndJ0PGRQl9DtZBgSt8wVeqwKmB4wCXG~mVA5KYJ3qdi2DOfiOgI9jV2LGbWv6mwXXPBm0i6L9B0mCzPurQq7AbA1Ew3LNl-i9hY1ucSh6fJ3VUjSTN8VQ~LvcRFk0Cz4edDAcP44QnK7zuhYecWxt4plLjcQNy4ydwSY4v-OxYB-oWtyhHRRgG9yh2Bp~AfPevXfzNRNbKYgJGBj4NeJBCFCeKGGCm5sYdgKdNxZRwfTfFezvCpQurGTAQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 10,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077215-Matcha.jpg?Expires=1733410052&Signature=hAe8Nnjig5jTxj1K0LxAKtfolBLifhVdbvUYkiwO~GHKvON~tuP9Xm5gyhynORyZr34vrtShMfE7OEwlATt~FNQsai3Tzmxm~41fxR6beuLiJpC4G3IFxuITeDhHYO7lNEUj90t0uxjywqH3BwU-N-8OrJj2j7N9o7lu2VZw~tUHojloClDuGegRcjQf12aRtpwy3bcRvEOedDDw~87oL5ksZ6rvVxF8WJAIuXjHtQU3qgAP5Ad-gDIptLWRjf7LPKWnp~ynPU1rYGqkIvNJ4MGl73AC1Kl-y-ihZOe6uhP-o55na6VKGlg0Op5DRc4bKZsriiUQ4z81hzxCGSKaEg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 16,
        name: "macadamia-matcha_966cbdef.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575319-macadamia-matcha_966cbdef.jpg?Expires=1733410052&Signature=e3x-rhPGtpJCGzfke8rasbaVaII8lRy5MMbBZ7NxsI8o-EOnJHeGbYTaYCgCJzjIBaq0jes67XVC6I1hE3Vnw0LezuD0hfEd9BHi7Fj-zMNfiEmsQJ5mFz03gqVP6ul1XNp-1P6-lxJWSGuq-rh8H3sdhFyLIoLNujnK5NhzOg~Jf3A~Hjg3CQXMnWA4ywCeYZHpRrgRGNjd64rvDyQZMq5cCSdWap98vldUJXiqw4PvebY0gmtnEAn48cu-AwW9ttb72q1MkcidnRNyaNjAbA8Ji40NZDsptQgknIG--6koM9TFgYep7p~KN4p12~5vTnLJXfNYYco1QHX~GLhfmA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 8,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Golden Mix 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 21848,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 11346,
      availableQuantity: 997716,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 997716,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
      {
        id: "5",
        name: "ICUI",
        percentage: "15.00",
        description: "",
        status: "active",
        type: "ICUI",
        categoryFavorable: {
          id: "5013",
          idParent: "5010",
          name: "Otro tipo de impuesto a favor",
          text: "Otro tipo de impuesto a favor",
          code: null,
          description: null,
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "106",
            name: "Otro tipo de impuesto a favor",
            key: "OTHER_TAX_TYPE_IN_FAVOR",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5044",
          idParent: "5041",
          name: "Otro tipo de impuesto por pagar",
          text: "Otro tipo de impuesto por pagar",
          code: null,
          description: null,
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "105",
            name: "Otro tipo de impuesto por pagar",
            key: "OTHER_TAX_TYPE_TO_PAY",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 4,
        name: "IMG_20200622_145613.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076848-IMG_20200622_145613.jpg?Expires=1733410052&Signature=TvOH4qtMnfjPTnrQ1v-s8hAEGPbYj5RWjKYeKlaQT8r3dnPHqFKlvEdFqijiScSRlOK1L9wa8ko5STIi2WD8vJ26lV8ucEpDf3KC2S61kTh~Dn3i8iQybrIGc3jV-GtL~W4XKlSGUrALRV4MZhCdvpAtAX46LvYV5z4ykTDutWpfqTJ2kXW8-79JpjdQO4136P~55fSl12wjao-pyB-dCdAyi-uQHEdZNpdzFrwa2KStybcZzApx-izHBKJ9MTE7cQkLcclxRcvy27zyqGqpW1-PSw4TrBhgSOVZ18z86ry4RnTAldoqj6DZWDcOcOZlmHCVFNwxruqdKg2ocvL1kA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 11,
        name: "Imagen2.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594573971-Imagen2.png?Expires=1733410052&Signature=GzJP85zQ-xIiVO4Q2TwofhOpoJF45rhf~cnXxL2EE-1frANAhZVtTdo6gz349zmxzSoa9tmYeASMqSPv2HNFdZs6EHBmLuO6hhBKwKP6WC8XWL8uSOgd1SBQDmaptwq2gn5oV-UkIsqLdop9V5VlpfF12TFMKK05ihS2Vn-JaFVW4E7ZL15onY~bui8UXD2IIwFNkEctmK4PrA83kKobqK02lYqX-K89wFT~hH9D5qQOYS-J4c7DfuZISQjvCu5iOtNMdyAR9yTXml~3WYERZUpRY6knLfeGPa6FDNhoKs~u1Y6wPWDT~2WvQGfGWZxcRgoxPDvXT8~9eu7JDQYYuQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 15,
        name: "macadamia-golden_eb7e5ceb.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575306-macadamia-golden_eb7e5ceb.jpg?Expires=1733410052&Signature=exK0pQy-tXFxCQs50pSuEXqRAnVlSy286mQ7YWnBB2PgHX-arm8TzTuNAQHqhXn5EASYs8~Cc5sGdB638Z7np5fu42wrEouMaL2MtAONx7D4n1GinC~HdC5T4r3ATwb~RzaPlIECc6FVnoUGmJeUZqsTNAmUoCpOtfELVC~cW33bkAlK-r9bNJ-10VVPnu2jAGF-HO9j34eL0c~qTD0ZVtZQ0GXUyFyhEnng7XyzpQHpej4vP-9hVBMEAoT2IhhwJ7STbX6Zc6J6X-tjOxFLJGmlV4g0-rSSASdPDyAojIhlDzLO9fcVFDaTeVHQ7j7nmPie1WqMVISo-Bt2DO~rnw__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 12,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 1Kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 36050.42,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2020-07-12",
      unit: "unit",
      unitCost: 20875,
      availableQuantity: 999488,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 999488,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 9,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 500g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 16387,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 9685,
      availableQuantity: 874,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 874,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 10,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Matchachá 500g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 53782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 34685,
      availableQuantity: 995,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 995,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 11,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Almendras 1kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 60672,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 33422,
      availableQuantity: 928,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 928,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 14,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Nueces 32g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 2941.19,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 100000,
      initialQuantityDate: "2020-12-11",
      unit: "unit",
      unitCost: 1765,
      availableQuantity: 99860,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 100000,
          availableQuantity: 99860,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 15,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañon 1kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 90000,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "unit",
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 26,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Pistacho 1Kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 196332,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 10000,
      initialQuantityDate: "2024-09-09",
      unit: "unit",
      unitCost: 137432,
      availableQuantity: 9991,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000,
          availableQuantity: 9991,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
] as const;

export const PROD_IMAGES = {
  1: imgAlmendra,
  2: imgMacadamia,
  3: imgMaranon,
  5: imgCocoa,
  4: imgMani,
  6: imgPink,
  7: imgMatcha,
  8: imgGolden,
  13: imgCanela,
  16: imgMaranon,
  17: imgMacadamia,
  18: imgAlmendra,
  19: imgMani,
  22: imgCocoa,
  23: imgCanela,
  24: imgPink,
  25: imgPistacho,
} as { [key: number]: string };

export const DISCOUNTS = [500]; // [13, 3, 5];
export const DISCOUT_PERCENTGE = 15;
export const hasDiscount = (id: number): boolean => DISCOUNTS.includes(id);

const notAProductId = [21, 20]; //insolvencia y otras cosas de Diego mr. accountant

export const PRODS = THE_PRODS.filter((i) => !notAProductId.includes(i.id)).map((i) => ({
  ...i,
  name: i.name.replace("Ã­ ", "í ").replace("Ã±Ã³", "ñó").replace("Ã¡", "á"),
}));
